import formatPrice from './priceFormat';

export function getPromotion(item, cart) {
    if (!Array.isArray(cart?.lineItems)) {
        return null;
    }

    const promotion = cart.lineItems.find((line) => line.type === 'promotion' && line.payload.lineItemId === item.id);
    if (!promotion) {
        return null;
    }

    const originalPromotion = cart.lineItems.find((line) => line.type === 'promotion' && line.id === promotion.payload.originalDiscountLineItemId);
    return originalPromotion || null;
}

export function renderPromotion(item, cart) {
    const promotion = getPromotion(item, cart) || null;

    if (!promotion) {
        return null;
    }

    let value = promotion.payload.value;
    const type = promotion.payload.discountType;

    if (type !== 'percentage') {
        return null;
    }

    value = value + '%';

    return	(
        <div className={"promotion"} style={{
            background: 'black',
            color: 'white',
            padding: '0.25rem 0.5rem',
            width: 'fit-content',
            margin: '0.5rem 0',
            fontSize: '11px'
        }}>
            { `${promotion.payload.code} ${value}` }
        </div>
    );
}
