export async function fetchCountryStates(countryId) {
	try {
		const request = await fetch('/api/checkout/country-states', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				countryId
			})
		});

		return await request.json();
	} catch (e) {
		console.log(e);
		return { data: [] };
	}
}